import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useGlobalContext } from '../context';
import useLocalState from '../utils/localState';

import api from '../utils/api';

function Login() {
  const { saveUser } = useGlobalContext();
  const history = useHistory();
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const { alert, showAlert, setLoading, hideAlert } = useLocalState();
  
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    hideAlert();
    setLoading(true);
    const { email, password } = values;
    const loginUser = { email, password };
    try {
      const { data } = await api.post(`/api/v1/auth/login`, loginUser);
      setValues({ name: '', email: '', password: '' });
      const {user} = data;
      saveUser(user);
      if (user.premium){
        const startDate = new Date(user.boughtDate);
        let finishDate = new Date(startDate);
        if (user.method == "monthly"){
            finishDate.setMonth(startDate.getMonth() + 1);
        }else if (user.method == "yearly"){
            finishDate.setFullYear(startDate.getFullYear() + 1)
        }
        const currentDate = new Date(user.currentDate);
        if (currentDate > finishDate){
          const data = await api.post("/api/v1/users/uptodatePremium");
          saveUser(data.user);
        }
      }
      showAlert({
        text: `Welcome, ${data.user.name}. Redirecting to dashboard...`,
        type: 'success',
      });
      setLoading(false);
      history.push('/');
    } catch (error) {
      showAlert({ text: error?.response?.data?.msg });
      setLoading(false);
    }
  };

  return (
    <div className="mt-20">
      <form className="w-[287px] flex flex-col items-center mx-auto" onSubmit={onSubmit}>
        <img className="w-[54px]" src="/logo.webp" alt="" />
        {alert.show && (
          <div className={`mt-10`}>{alert.text}</div>
        )}
        <input onChange={handleChange} className="mt-10 w-full placeholder:text-white rounded-[8px] px-[8px] py-[6px] bg-transparent outline-none border border-white" type='email' name='email' value={values.email} placeholder="Email" />
        <input onChange={handleChange} className="mt-2 w-full placeholder:text-white rounded-[8px] px-[8px] py-[6px] bg-transparent outline-none border border-white" type='password' name='password' value={values.password} placeholder="Password" />
        <button type='submit' className="rounded-[8px] bg-white text-black w-full font-bold py-2 mt-4">Login</button>
        <p className='mt-5'>
          Don't have an account?
          <Link to='/register' className='ml-2 text-blue-500'>
            Register
          </Link>
        </p>
      </form>
    </div>
  );
}

export default Login;
