import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useGlobalContext } from '../context';
import PaymentModal from '../components/PaymentModal';

function Home() {
  const { user } = useGlobalContext();
  const history = useHistory();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  useEffect(() => {
    if (!user?.premium)
      setTimeout(() => setShowPaymentModal(true),500);
  },[user])
  return (
    <>
      {user?.premium && <Redirect to='/aiscore' />}
      {!user && <Redirect to='/login' />}
      <p> This is home page</p>
      {!user?.premium && <PaymentModal text="Are you sure you want to upgrade your account to premium?" title="Buy Premium Account" hidden={!showPaymentModal} handleBuyNow={() => history.push('/buypremium')} handleCancelBuyNow = {() => history.push('aiscore')}/>}
    </>
  );
}

export default Home;
