import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../context';
import api from '../utils/api';
import {MONTHLY_STRIPE_PAYMENT_LINK, YEARLY_STRIPE_PAYMENT_LINK} from '../utils/constants';

const BuyPremium = () => {
    const {user, saveUser} = useGlobalContext();
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [paymentType, setPaymentType] = useState("");
    const [showError, setShowError] = useState(false);
    const [daysLeft, setDaysLeft] = useState(-1);

    useEffect(() => {
        if(user.premium){
            const startDate = new Date(user.boughtDate);
            let finishDate = new Date(startDate);
            if (user.method == "monthly"){
                finishDate.setMonth(startDate.getMonth() + 1);
            }else if (user.method == "yearly"){
                finishDate.setFullYear(startDate.getFullYear() + 1)
            }
            const currentDate = new Date(user.currentDate);
            if (currentDate < finishDate){
                let timeDiff = finishDate - currentDate;
                let diffInDays = timeDiff / (1000 * 60 * 60 * 24);
                setDaysLeft(Math.round(diffInDays));
            }else{
                setDaysLeft(-1);
            }
        }
    },[user])

    const addPayment = async (e) => {
        e.preventDefault();
        if (paymentType == "monthly"){
            window.open(`${MONTHLY_STRIPE_PAYMENT_LINK}?utm_content=${user.userId}&utm_term=${paymentType}`);
        }else if (paymentType == "yearly"){
            window.open(`${YEARLY_STRIPE_PAYMENT_LINK}?utm_content=${user.userId}&utm_term=${paymentType}`);
        }else {
            setShowError(true);
        }
    }

    const handleClickUpgrade = (e) => {
        e.preventDefault();
        setShowUpgrade(!showUpgrade);
    }

    const handleClickPaymentType = (type) => (e) => {
        e.preventDefault();
        setShowError(false);
        setPaymentType(type);
    }

    const handleClickCancel = async (e) => {
        e.preventDefault();
        const res = await api.post("/api/v1/users/cancelPremium")
        saveUser(res.data.user);
    }

    return (
        <div className='pl-[28px] md:pl-[347px] pr-[28px] min-h-screen pt-[120px] bg-black md:bg-[#101010]'>
            <h1 className='mb-[35px] md:block hidden'>Account & Plan</h1>
            <h1 className='text-xl'>Current Plan</h1>
            {user.premium && daysLeft > 0 ? (
                <div className='bg-black rounded-3xl max-w-[755px] py-4'>
                    <div className='flex flex-row justify-between mx-6'>
                        <div className='flex flex-col'>
                            <h1 className='md:text-xl text-base'>BirdView - PRO&nbsp;-&nbsp; 
                                {user?.method == "monthly" && "Monthly"}
                                {user?.method == "yearly" && "Yearly"}
                            </h1>
                            <h1 className='md:text-xl text-base'>{daysLeft}&nbsp;Days left</h1>
                        </div>
                        {/* <button onClick={handleClickCancel} className='bg-[#232527] w-[153px] my-7 rounded-full' hidden={!user.autoBought}>
                            <span className='md:text-base text-xs'>Cancel Plan</span>
                        </button> */}
                        {/* <div className='flex items-center' hidden={user.autoBought}>
                            <span>Canceled Plan</span>
                        </div> */}
                    </div>
                </div>
            ):(
                <div className='bg-black rounded-3xl max-w-[755px] py-4'>
                    <div className='flex flex-row justify-between mx-6'>
                        <h1 className='md:text-xl text-base'>No Plan</h1>
                        <button onClick={handleClickUpgrade} className='bg-[#232527] w-[153px] my-3 rounded-full'>
                            <span className='text-base'>Upgrade</span>
                        </button>
                    </div>
                </div>
            )}
            <div className="relative z-10" aria-labelledby="upgrade-to" role="dialog" aria-modal="true" hidden={!showUpgrade}>
                <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                        <div className="relative overflow-hidden text-left md:max-w-[755px] w-full sm:px-10 transition-all transform bg-transparent rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-3xl">
                            <div className='bg-[#161616] rounded-3xl py-3 px-6 my-2'>
                                <h1 className='md:text-xl sm:text-lg text-sm md:font-bold' id="upgrade-to">Upgrade to:</h1>
                                <div className='flex flex-row justify-between items-center md:py-2 px-2 my-2 bg-black border-2 border-white rounded-3xl'>
                                    <h1 className='md:text-xl sm:text-lg text-sm md:font-bold'>BirdView PRO</h1>
                                    <h1 className='md:text-xl sm:text-lg text-sm md:font-bold'>Full Access</h1>
                                </div>
                                <h1 className='my-2 md:text-xl sm:text-lg text-sm md:font-bold'>Choose Plan</h1>
                                <div className={`bg-black rounded-3xl cursor-pointer md:py-2 px-2 my-2 flex flex-row justify-between items-center transition-colors duration-500 border-2 ${paymentType == 'monthly' ? 'border-white' : 'border-transparent'}`}
                                    onClick={handleClickPaymentType("monthly")}>
                                    <h1 className='md:text-xl sm:text-lg text-sm md:font-bold'>Monthly</h1>
                                    <h1 className='md:text-xl sm:text-lg text-sm md:font-bold'>$39.99/Month</h1>
                                </div>
                                <div className={`bg-black rounded-3xl cursor-pointer md:py-2 px-2 my-2 flex flex-row justify-between items-center transition-colors duration-500 border-2 ${paymentType == 'yearly' ? 'border-white' : 'border-transparent'}`}
                                    onClick={handleClickPaymentType("yearly")}>
                                    <h1 className='md:text-xl sm:text-lg text-sm md:font-bold'>Yearly - <span className='text-[#87EFAC] md:text-lg text-sm'>safe 37%</span></h1>
                                    <h1 className='md:text-xl sm:text-lg text-sm md:font-bold'>$299.99/Year</h1>
                                </div>
                                <div className='flex flex-row justify-between gap-4'>
                                    <button className='border-[#009E2C] border-2 rounded-2xl max-w-[394px] w-full text-center md:py-3 py-1 hover:border-[#1A6AFF] mt-2'
                                        onClick={() => setShowUpgrade(false)}>
                                        <span className='md:text-lg text-sm'>Cancel</span>
                                    </button>
                                    <button className='bg-[#009E2C] rounded-2xl max-w-[394px] w-full text-center md:py-3 py-1 hover:bg-[#1A6AFF] mt-2'
                                        onClick={addPayment}>
                                        <span className='md:text-lg text-sm'>Checkout</span>
                                    </button>
                                </div>
                                <div className='mt-2 text-center text-red-600'>
                                    <span hidden={!showError}>Please select period.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyPremium;
