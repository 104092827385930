import React, { useEffect, useMemo } from "react";
import { useGlobalContext } from "../context";
import api from "../utils/api";
import { useLocation, useHistory } from "react-router-dom";

const SuccessIcon =
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.4695 0.232963C15.8241 0.561287 15.8454 1.1149 15.5171 1.46949L6.14206 11.5945C5.97228 11.7778 5.73221 11.8799 5.48237 11.8748C5.23253 11.8698 4.99677 11.7582 4.83452 11.5681L0.459523 6.44311C0.145767 6.07557 0.18937 5.52327 0.556912 5.20951C0.924454 4.89575 1.47676 4.93936 1.79051 5.3069L5.52658 9.68343L14.233 0.280522C14.5613 -0.0740672 15.1149 -0.0953599 15.4695 0.232963Z" fill="white" />
  </svg>;

export default function CompletePage() {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo( () => new URLSearchParams(search), [search]);
  const { user, saveUser } = useGlobalContext();

  useEffect(()=>{
    const fetchData = async () =>{
      const userId = searchParams.get("utm_content");
      const method = searchParams.get("utm_term");
      if (userId == user.userId) {
        const res = await api.post('/api/v1/users/upgradePremium', { method })
        saveUser(res.data.user);
        history.push('/');
      }
    }
    fetchData();
  },[searchParams])

  return (
    <div className="flex justify-center">
      <div className="flex" id="payment-status">
        <div id="status-icon" style={{ backgroundColor: "#30B130" }}>
          {SuccessIcon}
        </div>
        <h2 id="status-text">{"Payment succeeded"}</h2>
      </div>
    </div>
  );
}