import React, { useEffect, useState } from "react";
import api from "../utils/api";
import { useGlobalContext } from '../context';
import BrainSvg from '../components/BrainSvg';
import {useHistory} from 'react-router-dom';

const Score = () => {
  const history = useHistory();
  const [scores, setScores] = useState([]);
  const { aiscores, user, sendJsonMessage } = useGlobalContext();

  const fetchScores = async () => {
    try {
      const { data } = await api.get(`/api/v1/scores/get/all`);
      setScores(data.scores);
    } catch (error) {
      console.log("get score error=", error);

    }
  };

  useEffect(() => {
    sendJsonMessage({user,type:"aiscore"});
    const interval = setInterval(() => {
      sendJsonMessage({user,type:"aisocre"});
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchScores();
  }, []);
  
  useEffect(() => {
    if(aiscores) {
        setScores(aiscores);
    }
  }, [aiscores]);

  return (
    <div className="md:px-[10px] sm:pl-[28px] md:pl-[347px] sm:pr-[28px] min-h-screen bg-black md:bg-[#101010] md:rounded-t-[40px] md:py-[120px] py-[70px] px-4">
      <h1 className="md:block hidden mb-8">AI Scores</h1>
      <div className="flex flex-col md:gap-y-14 md:gap-x-10 gap-3 md:flex-row md:flex-wrap">
        {scores && scores.map((item) => (
          <div key={item.name} className="rounded-full md:rounded-[30px] md:mt-[12px] bg-[#232527] md:bg-black flex justify-between md:w-[240px] md:pt-[35px] md:pb-[15px] items-center p-2 pl-4 sm:p-4">
            <div className="relative flex items-center gap-2 md:block md:flex-col">
              <div className="md:absolute w-[30px] sm:w-[40px] h-[30px] sm:h-[40px] md:w-[50px] md:h-[50px] md:-translate-y-[120%] md:translate-x-[40%]">
                <img className="w-full h-full rounded-full" src={`/imgs/${item.name.toLowerCase().replace(/\s+/g, '')}.png`} alt="" />
              </div>
              <div className="flex flex-col justify-around md:flex-col-reverse">
                <p className="md:text-lg text-[12px] font-bold">{item.name}</p>
                <p className="md:text-lg text-[12px] md:text-[#9D9D9D]">{item.symbol}</p>
              </div>
            </div>
            <div className="bg-black md:bg-[#232527] rounded-full gap-[10px] sm:gap-[8px] flex items-center p-1 md:p-2 md:pr-4 pr-2">
              <BrainSvg/>
              <p className="md:font-bold text-green-500 text-sm sm:text-lg">{item.score.toFixed(2)}%</p>
            </div>
          </div>
        ))}
        {!user?.premium && <div onClick={() => history.push('/buypremium')} className="rounded-full md:rounded-[30px] md:mt-[12px] bg-[#232527] md:bg-black flex justify-between md:w-[240px] md:pt-[25px] md:pb-[25px] md:h-[106px] items-center p-2 px-4 sm:p-4">
          <div className="flex flex-row items-center justify-between w-full">
            <p className="text-sm">BirdView&nbsp;<span className="text-[16px]">PRO</span></p>
            <p className="text-sm">+</p>
            <p className="text-sm">Unlock all tokens</p>
          </div>
        </div>}
      </div>
    </div>
  );
}

export default Score;