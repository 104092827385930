import styled from 'styled-components';
import { useGlobalContext } from '../context';

function Dashboard() {
  const { user } = useGlobalContext();
  const { userId, role } = user;

  return (
    <div className='mt-[100px]'>
      <Wrapper className='page'>
        <h2>Hello there, {user.name}</h2>
        <p>
          Your ID : <span>{userId}</span>
        </p>
        <p>
          Your Role : <span>{role}</span>
        </p>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  p span {
    background: var(--primary-500);
    padding: 0.15rem 0.25rem;
    color: var(--white);
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
  }
`;

export default Dashboard;
