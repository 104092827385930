import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useGlobalContext } from '../context';
import api from '../utils/api';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VerifyPage = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoading } = useGlobalContext();
  const query = useQuery();

  const verifyToken = async () => {
    setLoading(true);
    try {
      const { data } = await api.post('/api/v1/auth/verify-email', {
        verificationToken: query.get('token'),
        email: query.get('email'),
      });
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isLoading) {
      verifyToken();
    }
  }, []);

  if (loading) {
    return (
      <div className='mt-20 w-[300px]'>
        <h2>Loading...</h2>
      </div>
    );
  }

  if (error) {
    return (
      <div className='mt-20 w-[300px]'>
        <h4>There was an error, please double check your verification link </h4>
      </div>
    );
  }

  return (
    <div className='mt-20 w-[300px]'>
      <h2>Account Confirmed</h2>
      <Link to='/login' className='btn'>
        Please login
      </Link>
    </div>
  );
};

const Wrapper = styled.section``;

export default VerifyPage;
