import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import FormRow from '../components/FormRow';
import api from '../utils/api';
import useLocalState from '../utils/localState';

function Register() {
  const [values, setValues] = useState({
    name: '',
    email: '',
    password: '',
  });

  const {
    alert,
    showAlert,
    loading,
    setLoading,
    success,
    setSuccess,
    hideAlert,
  } = useLocalState();

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    hideAlert();
    setLoading(true);
    const { name, email, password } = values;
    const registerNewUser = { name, email, password };

    try {
      const { data } = await api.post(
        `/api/v1/auth/register`,
        registerNewUser
      );

      setSuccess(true);
      setValues({ name: '', email: '', password: '' });
      showAlert({ text: data.msg, type: 'success' });
    } catch (error) {
      const { msg } = error.response.data;
      showAlert({ text: msg || 'there was an error' });
    }
    setLoading(false);
  };
  return (
    <div className="mt-20">
      <form className="w-[287px] flex flex-col items-center mx-auto" onSubmit={onSubmit}>
        <img className="w-[54px]" src="/logo.webp" alt="" />
        {alert.show && (
          <div className={`mt-10`}>{alert.text}</div>
        )}
        <input onChange={handleChange} className="mt-10 w-full placeholder:text-white rounded-[8px] px-[8px] py-[6px] bg-transparent outline-none border border-white" type='text' name='name' value={values.name} placeholder="Fullname" />
        <input onChange={handleChange} className="mt-2 w-full placeholder:text-white rounded-[8px] px-[8px] py-[6px] bg-transparent outline-none border border-white" type='email' name='email' value={values.email} placeholder="Email" />
        <input onChange={handleChange} className="mt-2 w-full placeholder:text-white rounded-[8px] px-[8px] py-[6px] bg-transparent outline-none border border-white" type='password' name='password' value={values.password} placeholder="Password" />
        <button type='submit' className="rounded-[8px] bg-white text-black w-full font-bold py-2 mt-4">Register</button>

        <p className='mt-5'>
          Already a have an account?
          <Link to='/login' className='ml-2 text-blue-500'>
            Log In
          </Link>
        </p>
      </form>
    </div>
  );
}

export default Register;
