import React, { useContext, useState, useEffect } from 'react';
import useWebSocket from 'react-use-websocket'
import api from './utils/api';
import { DOMAIN_URL } from './utils/constants';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  
  const [socketUrl, setSocketUrl] = useState(`wss://${DOMAIN_URL}/ws/`);
  // const [socketUrl, setSocketUrl] = useState('ws://localhost:5000/');

  const {sendMessage, lastMessage, sendJsonMessage} = useWebSocket(socketUrl);
  
  const [aiscores, setAIScores] = useState(null);
  const [portfolio, setPortfolio] = useState(null);

  const saveUser = (user) => {
    setUser(user);
  };

  const removeUser = () => {
    setUser(null);
  };

  const fetchUser = async () => {
    try {
      const { data } = await api.get(`/api/v1/users/showMe`);
      saveUser(data.user);
    } catch (error) {
      removeUser();
    }
    setIsLoading(false);
  };

  const logoutUser = async () => {
    try {
      await api.delete('/api/v1/auth/logout');
      removeUser();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(()=>{
    if (lastMessage !== null){
      const message = JSON.parse(lastMessage.data);
      if(message.aiscore) {
        setAIScores(message.aiscore);
      }
      if(message.portfolio) {
        setPortfolio(message.portfolio);
      }
    }
  },[lastMessage])

  return (
    <AppContext.Provider
      value={{
        isLoading,
        user,
        saveUser,
        logoutUser,
        fetchUser,
        sendMessage, 
        sendJsonMessage,
        aiscores, 
        portfolio,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppProvider };
