import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useGlobalContext } from '../context';
import PaymentModal from "./PaymentModal";

const Navbar = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	const { user, logoutUser } = useGlobalContext();
	const [openSideMenu, setOpenSideMenu] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

	const handleLogout = async (e) => {
		e.preventDefault();
		if(user) {
			await logoutUser();
		} else {
			history.push('/login');
		}
	}

	const handlePortfolio = (e) => {
		e.preventDefault();
		if (user.premium){
			history.push('/portfolio');
		}else{
			setShowPaymentModal(true);
		}
	}

	const handleBuyNow = (e) => {
		history.push('/buypremium');
		setShowPaymentModal(false);
	}

	const nav = {
		"/aiscore" : "AI Score",
		"/portfolio" : "Portfolio",
		"/buypremium" : "Account & Plan"
	}
	
	return user ? (
		<div className='z-10'>
			<div className="fixed top-0 flex items-center justify-between w-full md:p-5 px-5 py-4 bg-black z-10 md:hidden">
				<img className='h-[25px] w-[25px] md:h-[40px] md:w-[40px]' src="/logo.webp" alt="" />
				<span className='md:text-2xl text-lg font-bold md:hidden'>{nav[history.location.pathname]}</span>
				<button onClick={() => setOpenSideMenu(true)} className='md:hidden'>
					<svg className='h-[25px] w-[25px]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z" fill="#FFFFFF"/>
					</svg>
				</button>
			</div>
			<div className={`h-screen p-5 bg-[#101010] fixed top-0 ${ openSideMenu ? 'left-0' : 'left-full md:left-0' } z-10 transition-all dura\ md:w-[320px] w-full border-r border-white flex flex-col items-center`}>
				<div className='flex flex-row w-full justify-end md:justify-center'>
					<button onClick={() => setOpenSideMenu(false)}>
						<img className='md:block hidden' src="/logo.webp" alt="" />
						<svg className='md:hidden' width="30px" height="30px" viewBox="-2.5 -2.5 30.00 30.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6.96967 16.4697C6.67678 16.7626 6.67678 17.2374 6.96967 17.5303C7.26256 17.8232 7.73744 17.8232 8.03033 17.5303L6.96967 16.4697ZM13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697L13.0303 12.5303ZM11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303L11.9697 11.4697ZM18.0303 7.53033C18.3232 7.23744 18.3232 6.76256 18.0303 6.46967C17.7374 6.17678 17.2626 6.17678 16.9697 6.46967L18.0303 7.53033ZM13.0303 11.4697C12.7374 11.1768 12.2626 11.1768 11.9697 11.4697C11.6768 11.7626 11.6768 12.2374 11.9697 12.5303L13.0303 11.4697ZM16.9697 17.5303C17.2626 17.8232 17.7374 17.8232 18.0303 17.5303C18.3232 17.2374 18.3232 16.7626 18.0303 16.4697L16.9697 17.5303ZM11.9697 12.5303C12.2626 12.8232 12.7374 12.8232 13.0303 12.5303C13.3232 12.2374 13.3232 11.7626 13.0303 11.4697L11.9697 12.5303ZM8.03033 6.46967C7.73744 6.17678 7.26256 6.17678 6.96967 6.46967C6.67678 6.76256 6.67678 7.23744 6.96967 7.53033L8.03033 6.46967ZM8.03033 17.5303L13.0303 12.5303L11.9697 11.4697L6.96967 16.4697L8.03033 17.5303ZM13.0303 12.5303L18.0303 7.53033L16.9697 6.46967L11.9697 11.4697L13.0303 12.5303ZM11.9697 12.5303L16.9697 17.5303L18.0303 16.4697L13.0303 11.4697L11.9697 12.5303ZM13.0303 11.4697L8.03033 6.46967L6.96967 7.53033L11.9697 12.5303L13.0303 11.4697Z" fill="#ffffff"></path> </g></svg>
					</button>
				</div>
				<Link onClick={()=>setOpenSideMenu(false)} to='/' className={`flex transition-all duration-300 hover:bg-[#232527] bg-transparent rounded-full w-full px-5 py-3 gap-2 mt-16 border border-transparent hover:border-[#b336fe]`}>
					<img src="/imgs/tag.svg" alt="" />
					<span className='font-semibold'>Buy BDVW</span>
				</Link>
				<Link onClick={handlePortfolio} /*to='/portfolio'*/ className={`flex transition-all duration-300 ${ pathname === '/portfolio' ? 'bg-[#232527]' : 'hover:bg-[#232527]' } rounded-full w-full px-5 py-3 gap-2 mt-16`}>
					<img src="/imgs/portfolio.svg" alt="" />
					<span className='font-medium'>Portfolio</span>
				</Link>
				<Link onClick={()=>setOpenSideMenu(false)} to='/aiscore' className={`flex transition-all duration-300 ${ pathname === '/aiscore' ? 'bg-[#232527]' : 'hover:bg-[#232527]' } rounded-full w-full px-5 py-3 gap-2 mt-2`}>
					<img src="/imgs/eye.svg" alt="" />
					<span className='font-medium'>AI Scores</span>
				</Link>
				<Link onClick={()=>setOpenSideMenu(false)} to='/buypremium' className={`flex transition-all duration-300 ${ pathname === '/setting' ? 'bg-[#232527]' : 'hover:bg-[#232527]' } rounded-full w-full px-5 py-3 gap-2 mt-2`}>
					<img src="/imgs/setting.svg" alt="" />
					<span className='font-medium'>Account & Plan</span>
				</Link>
				<Link onClick={handleLogout} to='/' className={`flex transition-all duration-300 ${ pathname === '/setting' ? 'bg-[#232527]' : 'hover:bg-[#232527]' } rounded-full w-full px-5 py-3 gap-2 mt-2`}>
					<svg data-bbox="29 33 141.662 133.367" viewBox="0 0 200 200" height="19" width="19" xmlns="http://www.w3.org/2000/svg" data-type="shape">
						<g>
							<path d="m95.667 33.017 5.891 5.891-60.775 60.784 60.775 60.783-5.891 5.892L29 99.692l66.667-66.675ZM164.77 33l5.892 5.892-60.775 60.783 60.775 60.783-5.892 5.892-66.667-66.675L164.771 33Z" fillRule="evenodd" fill='#FFFFFF'></path>
						</g>
					</svg>
					<span className='font-medium'>Logout</span>
				</Link>
			</div>
			{ openSideMenu && <div onClick={() => setOpenSideMenu(false)} className='absolute inset-0 block w-screen h-screen md:hidden backdrop-blur-md'></div> }
			{!user.premium && <PaymentModal text={"You'll need to purchase a premium account to access the Portfolio page."} title="Access Restricted" hidden={!showPaymentModal} handleBuyNow={handleBuyNow } handleCancelBuyNow = {() => setShowPaymentModal(false)}/>}

		</div>
	) : null;
};

export default Navbar;
