import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home, Error, Register, Login, Verify, ProtectedRoute, ForgotPassword, CompletePage, ResetPassword, Score, Portfolio, BuyPremium } from './pages';
import Navbar from './components/Navbar';
import { useGlobalContext } from './context';

function App() {
  const { isLoading } = useGlobalContext();
  
  if (isLoading) {
    return (
      <section className='page page-center'>
        <div className='loading'></div>
      </section>
    );
  }
  
  return (
    <Router>
      <Navbar />
      <Switch>
        <ProtectedRoute path='/complete'>
          <CompletePage/>
        </ProtectedRoute>
        <Route path='/' exact>
          <Home />
        </Route>
        <Route path='/login' exact>
          <Login />
        </Route>
        <Route path='/register' exact>
          <Register />
        </Route>
        <ProtectedRoute path='/aiscore' exact>
          <Score />
        </ProtectedRoute>
        <ProtectedRoute path='/portfolio' exact>
          <Portfolio />
        </ProtectedRoute>
        <Route path='/forgot-password' exact>
          <ForgotPassword />
        </Route>
        <Route path='/user/verify-email' exact>
          <Verify />
        </Route>
        <Route path='/user/reset-password' exact>
          <ResetPassword />
        </Route>
        <ProtectedRoute path='/buypremium' exact>
          <BuyPremium/>
        </ProtectedRoute>
        <Route path='*'>
          <Error />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
